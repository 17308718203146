var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6c46e3da804575480796d80a13d68be995783f66"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const release = "005";

console.log(`Reskinned Takeback : v${release}`);

if (
  typeof process.env.REACT_APP_ENVIRON !== "undefined" &&
  process.env.REACT_APP_ENVIRON !== "test"
) {
  // TODO do we get anything extra from doing this?
  // https://medium.com/@vshab/create-react-app-and-sentry-cde1f15cbaa
  if (SENTRY_DSN !== null) {
    console.log(`Setting up Sentry with DSN ${SENTRY_DSN}`);
    Sentry.init({
      dsn:
        SENTRY_DSN ||
        "https://290d17d632024103965dd6acf04f1294@o200973.ingest.sentry.io/5879226",
      release: release,
      environment: process.env.REACT_APP_ENVIRON,
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: process.env.REACT_APP_ENVIRON,
      ignoreErrors: [
        "Non-Error promise rejection captured",
        "Network request failed",
        "Failed to fetch",
        "NetworkError",
        "AbortError",
        "3000ms timeout exceeded",
        "Object Not Found Matching Id",
        "Not implemented on this platform", // https://github.com/WordPress/openverse/issues/524
        // The rest of this list is from https://docs.sentry.io/clients/javascript/tips/
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        "Script error.",
        // Avast extension error
        "_avast_submit",
      ],
      denyUrls: [
        /otBannerSdk/i,
        /otSDKStub/i,
        /cdn.cookielaw.org/i,
        // The rest of this list is from https://docs.sentry.io/clients/javascript/tips/
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Browser extensions
        /extensions\//i,
        /chrome-extension/i,
        /safari-web-extension/i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      allowUrls: ["https://takeback.reskinned.clothing/"],
    });
  }
}
